import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import { Helmet } from 'react-helmet';
import keyboardFocus from 'keyboard-focus';
import { AnimationFeature, ExitFeature, MotionConfig } from 'framer-motion';

import HelveticaNowDisplayMd from '~assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Md.woff2';
import HelveticaNowDisplayRegular from '~assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff2';

const Root = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      keyboardFocus(document);
    }
  }, []);

  return (
    <Provider store={configureStore()}>
      <Helmet>
        <link
          rel='preload'
          as='font'
          href={HelveticaNowDisplayMd}
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          as='font'
          href={HelveticaNowDisplayRegular}
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link rel='preconnect' href='https://p.typekit.net' />
        <link
          rel='preload'
          href='https://use.typekit.net/mxp0chg.css'
          as='style'
        />
        <link rel='stylesheet' href='https://use.typekit.net/mxp0chg.css' />
      </Helmet>
      <MotionConfig features={[AnimationFeature, ExitFeature]}>
        {children}
      </MotionConfig>
    </Provider>
  );
};

export default Root;
