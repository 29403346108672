import React from 'react';
import './styles/index.scss';
import usePreviewMode from './hooks/usePreviewMode';

const PageWrapper = ({ children }) => {
  usePreviewMode();
  return <>{children}</>;
};

export default PageWrapper;
