import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from '@reach/router';

import { isPreviewMode } from '~utils/environments';
import { setData, setGlobalData, setURI } from '~redux/actions';

const messageHandler = (e, dispatch, page) => {
  if (e.data && e.data.event_id === 'data') {
    dispatch(setURI(e.data.uri));
    dispatch(setData(e.data.previewData, page));
    dispatch(setGlobalData(e.data.previewGlobalData));
  }
};

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const page = location.pathname.replace(/live-preview\//, '');

  useEffect(() => {
    if (isPreviewMode && typeof window !== undefined) {
      window.addEventListener('message', (e) =>
        messageHandler(e, dispatch, page)
      );

      window.parent.postMessage(
        {
          ready: true,
        },
        '*'
      );
    }
    return () => {
      if (isPreviewMode) {
        window.removeEventListener('message', (e) =>
          messageHandler(e, dispatch, page)
        );
      }
    };
  }, [dispatch, page]);
};
