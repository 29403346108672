export const SET_DATA = 'SET_DATA';
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';
export const SET_URI = 'SET_URI';
export const SET_APPLICATION_NAME = 'SET_APPLICATION_NAME';
export const CLEAR_APPLICATION_NAME = 'CLEAR_APPLICATION_NAME';

export const setData = (data, page) => ({
  type: SET_DATA,
  payload: {
    data,
    page,
  },
});

export const setGlobalData = (data) => ({
  type: SET_GLOBAL_DATA,
  payload: data,
});

export const setApplicationName = (data) => ({
  type: SET_APPLICATION_NAME,
  payload: data,
});

export const clearApplicationName = () => ({
  type: CLEAR_APPLICATION_NAME,
});

export const setURI = (uri) => ({
  type: SET_URI,
  payload: uri,
});
