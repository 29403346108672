import {
  CLEAR_APPLICATION_NAME,
  SET_APPLICATION_NAME,
  SET_DATA,
  SET_GLOBAL_DATA,
  SET_URI,
} from './actions';
import { removeTrailingSlash } from '~utils/helpers';

export default (state, { type, payload }) => {
  switch (type) {
    case SET_DATA: {
      const page = payload.page
        ? { [removeTrailingSlash(payload.page)]: payload.data }
        : {};
      return {
        ...state,
        ...page,
      };
    }
    case SET_GLOBAL_DATA: {
      return {
        ...state,
        global: payload,
      };
    }
    case SET_APPLICATION_NAME: {
      return {
        ...state,
        application: { name: payload },
      };
    }
    case CLEAR_APPLICATION_NAME: {
      return {
        ...state,
        application: { name: null },
      };
    }
    case SET_URI: {
      return {
        ...state,
        uri: payload,
      };
    }
    default:
      return state;
  }
};
