export function urlAddSlashOnTheEnd(url) {
  // check if there is slash on the end for url
  if (url && typeof url === 'string' && url[url.length - 1] !== '/') {
    url += '/';
  }
  return url;
}

export function getReactNodeText(node) {
  if (['string', 'number'].includes(typeof node)) {
    return node;
  }

  if (node instanceof Array) {
    return node.map(getReactNodeText).join('');
  }

  if (typeof node === 'object' && node) {
    return getReactNodeText(node.props.children);
  }

  return null;
}

export function isStringEqualToNoBreakSpace(text) {
  if (text) {
    return text === String.fromCharCode(160);
  }

  return false;
}

export function nestArray(array, length) {
  if (!array) return;
  return array.reduce((accum, el) => {
    if (accum.length < length) {
      accum.push([el]);
    } else {
      // Push element to the first element that has less elements than the next one.
      const toPushIndex = accum.findIndex((item, index) =>
        accum[index + 1]
          ? item.length <= accum[index + 1].length
          : item.length < accum[0].length
      );
      accum[toPushIndex || 0].push(el);
    }
    return accum;
  }, []);
}

export const removeTrailingSlash = (str) => str.replace(/\/$/, '');

export const parseHtmlEntities = (text) => {
  if (text) {
    return text.replace(/&amp;/g, '&');
  } else {
    return text;
  }
};
